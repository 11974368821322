














































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import { AppModule } from '@/store/modules/app'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { createImport, preprocessImport } from '@/api/consoleApi/imports'
import { CreateImportDbDto, ImportStrategyDbSettings, ImportStrategyEnum } from '@/api/consoleApi/dto/imports.dto'
import { get } from 'lodash'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsAddQueryColumnModal from '../../../../components/VsAddQueryColumnModal/Index.vue'
import axios from 'axios'
import { createSynchronization, getSynchronization } from '@/api/consoleApi/recipients'

@Component({
    name: 'ImportFromDb',
    components: {
        VsContainer,
        VsLoader,
        VsAddQueryColumnModal,
    },
})
export default class extends Vue {
    @Prop({ default: null }) list!: any
    private loading = false
    private advanceActive = false
    private hostUrl = ''
    private port = null
    private databaseName = ''
    private tableName = ''
    private username = ''
    private password = ''
    private condition = ''
    private columns: string[] = []
    private limit = 0
    private preprocessData = {}

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get isSynchronization () {
        return !!this.$route.matched.find((el: any) => el.name === 'createSyncIndex')
    }

    async createAndPreprocessImport () {
        this.loading = true
        const importId = await this.createImport()
        if (importId) {
            try {
                const preprocess = await preprocessImport(importId)
                this.preprocessData = preprocess.data.data
                this.$emit('submit', this.preprocessData)
                this.loading = false
            } catch (e) {
                console.log(e)

                let heading = this.$t('lists.importContacts.fromDb.preprocessError')
                let message = this.$t('lists.importContacts.fromDb.preprocessErrorMessage')
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 400) {
                        if (this.$te('lists.importContacts.fromDb.errors.' + e.response.data.code)) {
                            heading = this.$t('lists.importContacts.fromDb.errors.' + e.response.data.code)
                            message = ''
                        }
                    }
                }

                this.loading = false
                this.$root.$vsToast({
                    heading,
                    message,
                    aspect: VsToastAspectEnum.alert,
                    timeout: 8000,
                })
            }
        }
    }

    async addQueryColumns (): Promise<void> {
        try {
            await this.$refs.VsAddQueryColumnModal.open(this.columns)
        } catch (e) {
            console.log(e)
        }
    }

    private async createImport () {
        try {
            const importData: CreateImportDbDto = this.prepareDataImport()

            const resp = await createImport(importData)

            return resp.data
        } catch (e) {
            this.loading = false
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response.data.message['import_strategy_settings.host']) {
                        this.$refs.importDbForm.setErrors({
                            hostUrl: this.$t('lists.importContacts.fromDb.errors["' + e.response.data.message['import_strategy_settings.host'] + '"]'),
                        })
                    }
                }
            }
            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.fromDb.createImportError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private prepareDataImport (): CreateImportDbDto {
        const importStrategySettings: ImportStrategyDbSettings = {
            host: this.hostUrl,
            port: this.port || 3306,
            database_name: this.databaseName,
            username: this.username,
            password: this.password,
            table_name: this.tableName,
        }

        if (this.advanceActive) {
            if (this.limit > 0) importStrategySettings.limit = this.limit
            if (this.condition) importStrategySettings.condition = this.condition
            if (this.columns.length > 0) importStrategySettings.columns = this.columns
        }

        return {
            entity_id: this.isSuppressionImport ? '0' : this.listId,
            entity_type: this.isSuppressionImport ? 'suppression' : 'recipient',
            import_strategy: ImportStrategyEnum.db,
            import_strategy_settings: importStrategySettings,
        }
    }

    private async createSync () {
        this.loading = true
        try {
            const importData = {
                period: 'monthly',
                payload: this.prepareDataImport(),
            }

            const resp = await createSynchronization(this.listId, importData)

            const response = await getSynchronization(this.listId, resp.data)
            this.preprocessData = {
                pre_processing_info: response.data.data.preprocess_result,
                strategy_settings: {
                    skip_first_row: false,
                },
                strategy: ImportStrategyEnum.db,
                syncId: resp.data,
            }
            this.$emit('submit', this.preprocessData)

            return resp.data
        } catch (e) {
            console.log(e)
            let heading = this.$t('lists.importContacts.fromUrl.createImportError')
            let message = this.$t('lists.importContacts.fromUrl.preprocessErrorMessage')
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response.data.message['import_strategy_settings.url']) {
                        this.$refs.importUrlForm.setErrors({
                            apiUrl: this.$t('lists.importContacts.fromUrl.errors["' + e.response.data.message['import_strategy_settings.url'] + '"]'),
                        })
                    }
                    if (this.$te('lists.importContacts.fromUrl.errors.' + e.response.data.code)) {
                        heading = this.$t('lists.importContacts.fromUrl.errors.' + e.response.data.code)
                        message = ''
                    }
                }
            }

            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}
