






































































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsAddQueryColumnModal',
})
export default class extends Vue {
    private items = ['']
    private dialog = false
    private loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    public async save () {
        const valid = await this.$refs.columnForm.validate()
        if (!valid) return
        this.loading = true
        try {
            this.items = this.items.filter(el => String(el).trim())
            this.$emit('update', this.items)
            this.close()
            this.loading = false
        } catch (e) {
            console.log(e)
            this.loading = false
        }
    }

    public open (items: string[]) {
        this.items = items.length > 0 ? JSON.parse(JSON.stringify(items)) : ['']
        this.dialog = true
    }

    public close () {
        this.dialog = false
    }

    private deleteRow (index: number) {
        this.items.splice(index, 1)
        if (this.items.length === 0) this.items = ['']
    }
}
